import styled from "styled-components";
import {
  Rem, BP, Colors, Ratio, 
} from "../../commons/Theme";
import { StyledVimeoPlayer, StyledVimeoPlayerThumb } from "../VimeoPlayer/style";

export const StyledMultiVideoPlayer = styled.div`
  width: 100%;
  height: 100%;
  ${StyledVimeoPlayer} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  ${StyledVimeoPlayerThumb} {
    object-fit: cover;
    object-position: center;
  }
`;

export const StyledMultiVideoPlayerMainVideo = styled.div`
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
  background: transparent;
  position: relative;
`;

export const StyledMultiVideoPlayerItems = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: ${Rem(16)};
`;

export const StyledMultiVideoPlayerItem = styled.div`
  position: relative;
  ${Ratio(1, 1)};
  min-width: 10%;
  max-width: 10%;
  margin-right: ${Rem(24)};

  @media (${BP.ipad}) {
    min-width: calc(10% - ${Rem(16)});
    max-width: calc(10% - ${Rem(16)});
  }

  background-image: url('${(props) => props.image}');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &.selected {
    border: 3px solid ${Colors.yellow};
  }
`;
